#root {
  width: 100%;
}

.App {
  text-align: center;
}

.monospaced-text {
  font-family: monospace;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.section-header {
  text-align: center;
}

.MuiDataGrid-row:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.06);
}

.MuiDataGrid-row {
  cursor: pointer;
}

.MuiChip-root:hover {
  background-color: #d3d2d2;
  cursor: pointer;
}

#system-sessions-key {
  display: block;
  margin: auto;
  width:fit-content;
}

.near.MuiDataGrid-row, #system-sessions-key > .near {
  font-weight: bold;
}

.past.MuiDataGrid-row, #system-sessions-key > .past {
  font-weight: lighter;
}

div.header-subdued-note {
  float: right;
  width: 40%;
  text-align: right;
  font-weight: lighter;
}
